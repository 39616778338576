<template>
  <div class="load-data-item">
    <Title :title="title"></Title>
    <div class="load-input" v-if="!getDetailType && !uploading">
      <Upload
        :disabled="disabled || !$can('EditLoadData', 'STM')"
        @input="onInput"
        class="custom-upload"
      />
    </div>
    <div class="uploading load-complete" v-if="uploading">
      <div class="content__uploading">
        Subiendo: {{ fileName }}
        <div class="uploading-bar">
          <div class="uploading-progress" :style="{ width: `${progress}%` }"></div>
        </div>
      </div>
    </div>
    <div class="load-complete" v-if="getDetailType && !uploading">
      <Upload
        :disabled="disabled || !$can('EditLoadData', 'STM')"
        @input="onInput"
        class="custom-upload--invisible"
      />
      <div
        class="actions__load"
        v-if="this.getDetailType.errorMessage !== '' || $can('EditLoadData', 'STM')"
      >
        <Dropdown position="bottom-right">
          <DropdownItem @click="removeFile" v-if="$can('EditLoadData', 'STM')">
            Remover archivo
          </DropdownItem>
          <DropdownItem v-if="this.getDetailType.errorMessage !== ''" @click="getLogs">
            Descargar logs
          </DropdownItem>
        </Dropdown>
      </div>
      <div class="load-complete-content">
        <div class="load-content__icon" :class="`icon--${getDetailType.batchFileStatusCode}`">
          <i class="mdi mdi-close-circle" v-if="getDetailType.batchFileStatusCode === 'ERROR'"></i>
          <i
            class="mdi mdi-check-circle"
            v-if="getDetailType.batchFileStatusCode === 'SUCCESS'"
          ></i>
        </div>
        <div class="load-content__text">
          <p class="file__name" :title="getDetailType.fileName">
            {{ getDetailType.fileName }}
          </p>
          <p class="file__time_ago">
            {{ getToTime() }}
          </p>
          <p>{{ getErrorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LoadDataItem',
  props: {
    title: String,
    batchDetail: Object,
    batchDetailType: String,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      urlApiBase: process.env.VUE_APP_ROOT_API_COMMON,
      uploading: false,
      fileName: '',
      progress: 0,
    };
  },
  methods: {
    onInput(files) {
      const file = files;
      this.fileName = file.name;
      const formData = new FormData();
      formData.append('formData', file);
      const { id } = this.$route.params;
      this.uploading = true;
      this.upload(
        `file/loads/${id}/uploadFiles/${this.batchDetailType}/STM`,
        formData,
        'common',
        this.onUpload
      )
        .then(() => {
          this.$root.$emit('reloadData');
          this.uploading = false;
        })
        .catch(() => {
          this.$root.$emit('reloadData');
          this.uploading = false;
        });
    },
    onUpload(progressEvent) {
      this.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    },
    getToTime() {
      moment.locale('es');
      return moment(this.getDetailType.creationDate).fromNow();
    },
    getLogs() {
      const url = `${this.urlApiBase}${this.getDetailType.endpointLog}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.click();
      link.remove();
    },
    removeFile() {
      this.delete('file/loads/detail', this.getDetailType.batchFileID, 'common').then(() => {
        this.$root.$emit('reloadData');
      });
    },
  },
  computed: {
    getDetailType() {
      if (!this.batchDetailType || (this.batchDetail.batches || []).length === 0) {
        return null;
      }
      return this.batchDetail.batches.find((x) => x.fileType === this.batchDetailType);
    },
    getErrorMessage() {
      if (this.getDetailType.batchFileStatusCode === 'ERROR') {
        if (this.getDetailType.errorMessage !== '') {
          return this.getDetailType.errorMessage;
        }
        return 'Error en formato de archivo. Favor de revisar el formato.';
      }
      return '';
    },
  },
  watch: {
    uploading(newValue) {
      this.$emit('uploading', newValue);
    },
  },
};
</script>

<style lang="scss">
.custom-upload--invisible {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 20;
  opacity: 0;
}
.content__uploading {
  width: 100%;
}

.uploading-bar {
  height: 5px;
  width: 100%;
  display: block;
  background-color: #d8e3eb;
  margin-top: 10px;
  position: relative;
}
.uploading-progress {
  position: absolute;
  top: 0;
  height: 5px;
  width: 0;
  transition: all 0.2s ease-in-out;
  left: 0;
  background-color: #5b89f2;
}

.file__time_ago {
  font-size: 14px;
  color: #a5acbc;
  margin-top: -8px !important;
}
.actions__load {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 22;
}
.load-data-item {
  .pw-title {
    margin-bottom: 8px;
  }
  .pw-title__title {
    margin-bottom: 0 !important;
  }
  margin-bottom: 16px;
  position: relative;
}
.custom-upload {
  .info-upload__image {
    display: none;
  }
  &.upload {
    min-height: 240px !important;
    box-sizing: border-box;
  }
}
.load-complete {
  position: relative;
  min-height: 240px;
  background-color: #fbfbfb;
  padding: 18px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #bdc6cc;
  box-sizing: border-box;
}

.load-complete-content {
  display: flex;
  p {
    margin: 0;
    margin-bottom: 8px;
  }
  .file__name {
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    font-weight: 700;
    max-width: 350px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.is-small-upload {
  .file__name {
    max-width: 230px;
  }
}

.icon {
  &--ERROR {
    color: #f25555;
    font-size: 22px;
  }
  &--SUCCESS {
    color: #19bf95;
    font-size: 22px;
  }
}
.load-content__icon {
  margin-right: 16px;
}
</style>
